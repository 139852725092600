// import React from "react"
// import { Link } from "gatsby"

const serverValidations = new Function()

serverValidations.createServerPartnerSchema = function(result) {
  let errorObj = {}

  if (result != null) {
    switch (result.ErrorCode) {
      case 99:
        if (
          result.FieldErrorList !== null &&
          result.FieldErrorList.length > 0
        ) {
          result.FieldErrorList.forEach(fieldError => {
            if (
              fieldError.FieldName !== null &&
              fieldError.FieldName !== "" &&
              (fieldError.ErrorMessage !== null) &
                (fieldError.ErrorMessage !== "")
            ) {
              switch (fieldError.FieldName.toLowerCase()) {
                case "firstname":
                  errorObj.firstName = fieldError.ErrorMessage
                  break
                case "lastname":
                  errorObj.lastName = fieldError.ErrorMessage
                  break
                case "email":
                  errorObj.email = fieldError.ErrorMessage
                  break
                case "phone":
                  errorObj.phone = fieldError.ErrorMessage
                  break
                case "extension":
                  errorObj.extension = fieldError.ErrorMessage
                  break
                case "company":
                  errorObj.company = fieldError.ErrorMessage
                  break
                case "website":
                  errorObj.website = fieldError.ErrorMessage
                  break
                case "address1":
                  errorObj.address1 = fieldError.ErrorMessage
                  break
                case "address2":
                  errorObj.address2 = fieldError.ErrorMessage
                  break
                case "city":
                  errorObj.city = fieldError.ErrorMessage
                  break
                case "state":
                  errorObj.state = fieldError.ErrorMessage
                  break
                case "zipcode":
                  errorObj.zipcode = fieldError.ErrorMessage
                  break
                case "terms":
                  errorObj.terms = fieldError.ErrorMessage
                  break
              }
            }
          })
        }
        break  
      case 101:
        alert("Partner Signup information not found.")
        break       
      case 100: //General Exception
        document.location = "partner-signup-error"
        break      
      default:
        document.location = "partner-signup-error"
        break
    }
  }

  return errorObj
}

serverValidations.createServerLeadSchema = function(result, formRef) {
  let errorObj = {}

  if (result !== null) {
    switch (result.ErrorCode) {
      case 99:
        if (
          result.FieldErrorList !== null &&
          result.FieldErrorList.length > 0
        ) {
          result.FieldErrorList.forEach(fieldError => {
            if (
              fieldError.FieldName !== null &&
              fieldError.FieldName !== "" &&
              (fieldError.ErrorMessage !== null) &
                (fieldError.ErrorMessage !== "")
            ) {
              switch (fieldError.FieldName.toLowerCase()) {
                case "firstname":
                  errorObj.first_name = fieldError.ErrorMessage
                  break
                case "lastname":
                  errorObj.last_name = fieldError.ErrorMessage
                  break
                case "company":
                  errorObj.company = fieldError.ErrorMessage
                  break
                case "companysize":
                  errorObj["00Nf4000009OQ2D"] = fieldError.ErrorMessage
                  break
                case "email":
                  errorObj.email = fieldError.ErrorMessage
                  break
                case "phone":
                  errorObj.phone = fieldError.ErrorMessage
                  break
                case "comments":
                  errorObj.comments = fieldError.ErrorMessage
                  // no comment for demo
                  break
              }
            }
          })
        }
        break
      case 101:
        alert("Contact sales form information not found.")
        break
      case 100: //General Exception
        formRef.submit()
        break
      default:
        formRef.submit()
        break
    }
  }

  return errorObj
}

serverValidations.createServerServiceSchema = function(result, formRef) {
  const errorObj = {}

  if (result != null) {
    switch (result.ErrorCode) {
      case 99:
        if (result.FieldErrorList != null && result.FieldErrorList.length > 0) {
          result.FieldErrorList.forEach(fieldError => {
            if (
              fieldError.FieldName !== null &&
              fieldError.FieldName !== "" &&
              (fieldError.ErrorMessage !== null) &
                (fieldError.ErrorMessage !== "")
            ) {
              switch (fieldError.FieldName.toLowerCase()) {
                case "name":
                  errorObj.name = fieldError.ErrorMessage
                  break
                case "email":
                  errorObj.email = fieldError.ErrorMessage
                  break
                case "phone":
                  errorObj.phone = fieldError.ErrorMessage
                  break
                case "comments":
                  errorObj.description = fieldError.ErrorMessage
                  break
              }
            }
          })
        }
        break
      case 101:
        alert("Contact service form information not found.")
        break
      case 100: //General Exception
        formRef.submit()
        break
      default:
        formRef.submit()
        break
    }
  }

  return errorObj
}

serverValidations.createSignupErrorSchema = function(result) {
  console.log(result)
  const errorObj = {}

  if (result !== null) {
    //Validation Error Code
    switch (result.ErrorCode) {
      case 103:
        if (
          result.FieldErrorList !== null &&
          result.FieldErrorList.length > 0
        ) {
          result.FieldErrorList.forEach(fieldError => {
            if (
              fieldError.FieldName !== null &&
              fieldError.FieldName !== "" &&
              (fieldError.ErrorMessage !== null) &
                (fieldError.ErrorMessage !== "")
            ) {
              switch (fieldError.FieldName.toLowerCase()) {
                case "companyname":
                  errorObj.company = fieldError.ErrorMessage
                  // SetErrorField("CompanyName", value.ErrorMessage);
                  break
                case "firstname":
                  errorObj.firstName = fieldError.ErrorMessage
                  // SetErrorField("FirstName", value.ErrorMessage);
                  break
                case "lastname":
                  errorObj.lastName = fieldError.ErrorMessage
                  // SetErrorField("LastName", value.ErrorMessage);
                  break
                case "email":
                  errorObj.email = fieldError.ErrorMessage
                  // SetErrorField("Email", value.ErrorMessage);
                  break
                case "phone":
                  errorObj.phoneNumber = fieldError.ErrorMessage
                  // SetErrorField("Phone", value.ErrorMessage);
                  break
                case "phoneext":
                  errorObj.phoneExt = fieldError.ErrorMessage
                  // SetErrorField("PhoneExt", value.ErrorMessage);
                  break
                case "address1":
                  errorObj.address1 = fieldError.ErrorMessage
                  // SetErrorField("Address1", value.ErrorMessage);
                  break
                case "address2":
                  errorObj.address2 = fieldError.ErrorMessage
                  // SetErrorField("Address2", value.ErrorMessage);
                  break
                case "city":
                  errorObj.city = fieldError.ErrorMessage
                  // SetErrorField("City", value.ErrorMessage);
                  break
                case "country":
                  errorObj.country = fieldError.ErrorMessage
                  // SetErrorField("Country", value.ErrorMessage);
                  break
                case "state":
                  errorObj.state = fieldError.ErrorMessage
                  // SetErrorField("State", value.ErrorMessage);
                  break
                case "zipcode":
                  errorObj.zip = fieldError.ErrorMessage
                  // SetErrorField("ZipCode", value.ErrorMessage);
                  break
                case "ccholdername":
                  errorObj.ccName = fieldError.ErrorMessage
                  // SetErrorField("CardHolder", value.ErrorMessage);
                  break
                case "ccnumber":
                  errorObj.ccNumber = fieldError.ErrorMessage
                  // SetErrorField("CardNumber", value.ErrorMessage);
                  break
                case "ccexpiryyear":
                  errorObj.ccExpYear = fieldError.ErrorMessage
                  // SetErrorField("ExpDate", value.ErrorMessage);
                  break
                case "ccexpirymonth":
                  errorObj.ccExpMonth = fieldError.ErrorMessage
                  // SetErrorField("ExpDate", value.ErrorMessage);
                  break
                case "cvccode":
                  errorObj.cvc = fieldError.ErrorMessage
                  // SetErrorField("CVSCode", value.ErrorMessage);
                  break
                case "terms":
                  errorObj.tos = fieldError.ErrorMessage
                  // var formValidator = new FormValidator("sectionValidationMessage");
                  // formValidator.addError("terms");
                  // SetErrorField("terms", value.ErrorMessage);
                  break
                default:
                  break
              }
            }
          })
        }
        // EnableSignupButton(true);
        break
      case 101: //General Exception
        if ( result.FieldErrorList !== null && result.FieldErrorList.length > 0 ) {
          result.FieldErrorList.forEach(fieldError => {
            if (
              fieldError.FieldName !== null &&
              fieldError.FieldName !== "" &&
              (fieldError.ErrorMessage !== null) &
              (fieldError.ErrorMessage !== "")
            ) {
              switch (fieldError.FieldName.toLowerCase()) {
                case "email":
                  errorObj.email = fieldError.ErrorMessage
                  break
                default:
                  break
              }
            }
          })
        } else {
          alert("An error has occurred. Please try again.")
        }
        break
      case 104:
        errorObj.email = result.ErrorMessage;
        break
      default:
        alert("An error has occurred. Please try again.")
    }
  } else {
    alert("An error has occurred. Please try again.")
  }

  return errorObj
}

serverValidations.createForgotPasswordSchema = function(result) {
  const errorObj = {}

  if (result.FieldErrorList !== null && result.FieldErrorList.length > 0) {
    result.FieldErrorList.forEach(fieldError => {
      if (
        fieldError.FieldName !== null &&
        fieldError.FieldName !== "" &&
        (fieldError.ErrorMessage !== null) & (fieldError.ErrorMessage !== "")
      ) {
        switch (fieldError.FieldName.toLowerCase()) {
          case "email":
            errorObj.email = fieldError.ErrorMessage
          default:
            break
        }
      }
    })
  }

  return errorObj
}

serverValidations.createResetPasswordErrorSchema = function(result) {
  const errorObj = {}

  if (result.FieldErrorList !== null && result.FieldErrorList.length > 0) {
    result.FieldErrorList.forEach(fieldError => {
      if (
        fieldError.FieldName !== null &&
        fieldError.FieldName !== "" &&
        (fieldError.ErrorMessage !== null) & (fieldError.ErrorMessage !== "")
      ) {
        switch (fieldError.FieldName.toLowerCase()) {
          case "newpassword":
            errorObj.newPassword = fieldError.ErrorMessage
            break
          case "confirmpassword":
            errorObj.confirmPassword = fieldError.ErrorMessage
            break
        }
      }
    })
  }

  return errorObj
}

export default serverValidations
